<template>
  <div v-if="miniview" class="min-h-full flex flex-col items-center">
    <h4 class="text-3xl sm:text-4.5xl font-bold">MINIVIEWs™ Questions</h4>
    <span class="text-center tesxt-xs mb-5 font-light text-blue-800"
      >Please complete and submit all questions or you may lose your work when
      closing the tab.</span
    >
    <MiniviewQuestions
      class="w-full"
      :can-answer="true"
      @questionAnswered="onQuestionAnswered"
    />

    <div class="flex mt-11">
      <button
        type="button"
        class="bg-indigo-gradiant text-white px-28 h-16 rounded-full font-bold text-xl font-roboto uppercase"
        :class="{
          'opacity-50 cursor-not-allowed':
            loading || answerForm.answers.length == 0
        }"
        :disabled="loading || answerForm.answers.length == 0"
        @click="submitAnswers"
      >
        <span v-if="loading">
          <loading-icon class="h-5 w-5" />
        </span>
        <span v-else>Submit</span>
      </button>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapGetters, mapMutations } from "vuex";
import MiniviewQuestions from "@/components/miniviews/individual/MiniviewQuestions";

export default {
  name: "MiniviewAnswerQuestions",

  components: { MiniviewQuestions },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      miniview: "miniviews/current",
      answerForm: "miniviews/answerForm"
    })
  },

  mounted() {
    if (this.answerForm.name == "") {
      this.$router.push({ name: "MiniviewAnswerName" });
    }
  },

  methods: {
    ...mapMutations({
      updateAnswer: "miniviews/updateAnswer"
    }),

    onQuestionAnswered(a) {
      this.updateAnswer(a);
    },

    async submitAnswers() {
      try {
        this.loading = true;
        const { data } = await api.post(
          `/miniviews/${this.miniview.uuid}/submit-answer`,
          this.answerForm
        );
        if (data.success) {
          this.$router.push({ name: "AnswerSubmitted" });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
